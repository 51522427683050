import React, { useCallback, useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import { Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { post } from '../../../services/httpService';
import { openNotificationError } from '../../Notification/Notification';
import { shopConfig } from '../../../Globals/ShopConfig';
import { ProviderChildProps } from '../Provider';
import Translator from '../../../services/translator';

import payconiq_by_bancontact from '../../../assets/images/payconiq-horizontal-pos.png';
import PayconiqModel from '../../../Models/ProviderData/Payconiq/PayconiqModel';
import {
  pagesConstant,
  PaymentStatusConstant,
} from '../../../Constants/Constants';
import ModalEmisys from '../../Modal/ModalEmisys';
import './Payconiq.css';

const Payconiq = (props: ProviderChildProps) => {
  const intervalRef = useRef(null);
  const handleAjaxRef = useRef(null);
  const [loading, setLoading] = useState(false);
  let providerData = null;
  if (
    props.provider?.providerData !== null &&
    props.provider?.providerData instanceof PayconiqModel
  ) {
    providerData = props.provider?.providerData;
  }

  const closeModal = useCallback(() => {
    if (props.handleClose) {
      props.handleClose();
    } else {
      props.handleRedirect(pagesConstant.payment.failure);
    }
  }, [props]);

  const closePayconiq = useCallback(() => {
    setLoading(true);
    post(shopConfig.router.paymentPayconiqCancelUrl, {
      id: props.provider.id,
      signature: props.provider.signature,
    })
      .then(() => {
        closeModal();
      })
      .catch((error) => {
        openNotificationError(
          Translator.trans('error.internal_error.title'),
          error.body?.message
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [closeModal, props.provider.id, props.provider.signature]);

  const checkPaymentStatus = useCallback(() => {
    if (handleAjaxRef.current) {
      return;
    }

    handleAjaxRef.current = $.ajax({
      url: providerData.checkStatusUrl,
      cache: false,
    })
      .done((data) => {
        if (data.status === PaymentStatusConstant.paid) {
          props.handleRedirect(pagesConstant.payment.success);
        }

        if (data.status === PaymentStatusConstant.cancel) {
          openNotificationError(
            Translator.trans('error.internal_error.title'),
            Translator.trans('error.internal_error.message')
          );

          closeModal();
        }
      })
      .always(() => {
        handleAjaxRef.current = null;
      });
  }, [closeModal, props, providerData?.checkStatusUrl]);

  useEffect(() => {
    if (providerData.checkStatusUrl) {
      intervalRef.current = setInterval(checkPaymentStatus, 1000);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [checkPaymentStatus, props.provider, providerData?.checkStatusUrl]);

  return (
    <ModalEmisys
      closable={false}
      className={'payconiqModal'}
      maskClosable={false}
      open={props.isOpen}
      footer={null}
      centered
    >
      <div id="qrBox" className="payconiqBox">
        <div className="payconiqLogo">
          <img src={payconiq_by_bancontact} alt={'payconiq by bancontact'} />
        </div>
        <Spin spinning={loading}>
          <div className="payconiqFrame">
            <div className={'close-payconiq-btn'} onClick={closePayconiq}>
              <CloseOutlined />
            </div>

            <div className="payconiqTitle">
              <div>{Translator.trans('payment.scanAndPayWith')}</div>
              <div>Payconiq by Bancontact</div>
            </div>

            <div className="payconiqAmount">
              {props.provider?.amount?.formattedPrice}
            </div>

            <figure className="qrCode">
              <img src={providerData?.qrCodeUrl} alt={'QR-code'} />
            </figure>

            <div className="payconiqPayLink">
              <a href={providerData?.deepLinkUrl}>
                {Translator.trans('payment.iAmOnMobileDevice')}
              </a>
            </div>
          </div>
        </Spin>
      </div>
    </ModalEmisys>
  );
};

export default Payconiq;
