import React, { useCallback, useEffect } from 'react';
import { Collapse } from 'antd';
import EmisysButton from '../EmisysButton/EmisysButton';
import TicketOptions from '../MultipleChoice/TicketOptions';
import { formatTime } from '../../services/formatDate';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Translator from '../../services/translator';
import SessionModel from '../../Models/SessionModel';
import { shopConfig } from '../../Globals/ShopConfig';
import { SessionListChildProps } from './SessionList';
import { getSessionOptionsRequest } from '../../redux/actions/options.actions';
import { useDispatch } from 'react-redux';
import './Sessions.css';

const { Panel } = Collapse;

const collapseHeader = (
  session: SessionModel,
  showAvailabilityIndicators: boolean
) => (
  <>
    <div className={'option-list-item-header'}>
      <div>
        <span className={'session-details-hour-start'}>
          {formatTime(session.dateStart)}
        </span>
        <span className={'session-details-hour-split'}>{' - '}</span>
        <span className={'session-details-hour-end'}>
          {formatTime(session.dateStop)}
        </span>
      </div>
      <div>{session.places.join(', ')}</div>
      <div>{session.performers.join(', ')}</div>
      <div>{session.language}</div>
      {session.qtyRemaining && showAvailabilityIndicators ? (
        <div className={'list-item-qty-remaining'}>
          {Translator.trans('product.qtyRemainingTicket.quantity', {
            quantity: session.qtyRemaining,
          })}
        </div>
      ) : null}
    </div>
    <div className={'option-list-item-header-mobile'}>
      {session.performers && session.performers.length > 0 ? (
        <div className={'session-options-item-header-mobile'}>
          <span>{Translator.trans('session.performers')}</span>
          <span>{session.performers.join(', ')}</span>
        </div>
      ) : null}
      {session.language && session.language !== '' ? (
        <div className={'session-options-item-header-mobile'}>
          <span>{Translator.trans('session.language')}</span>
          <span>{session.language}</span>
        </div>
      ) : null}
      {session.places && session.places.length > 0 ? (
        <div className={'session-options-item-header-mobile'}>
          <span>{Translator.trans('session.places')}</span>
          <span>{session.places.join(', ')}</span>
        </div>
      ) : null}
      <div className={'session-options-item-header-mobile'}>
        <span className={'session-hours-mobile'}>
          {Translator.trans('session.hours')}
        </span>
        <span className={'session-details-hour-start'}>
          {formatTime(session.dateStart)}
        </span>
        <span className={'session-details-hour-split'}>{' - '}</span>
        <span className={'session-details-hour-end'}>
          {formatTime(session.dateStop)}
        </span>
      </div>
      {session.qtyRemaining && showAvailabilityIndicators ? (
        <div className={'list-item-qty-remaining'}>
          {Translator.trans('product.qtyRemainingTicket.quantity', {
            quantity: session.qtyRemaining,
          })}
        </div>
      ) : null}
    </div>
  </>
);

interface SessionListOptionItemProps extends SessionListChildProps {
  session: SessionModel;
  isDeletable: boolean;
  selectedSessions: SessionModel[];
}

const SessionListOptionItem = (props: SessionListOptionItemProps) => {
  const dispatch = useDispatch();
  const showAvailabilityIndicators = shopConfig.pos.showAvailabilityIndicators;

  const openSessionOptionCollapse = useCallback(() => {
    if (props.product && props.session) {
      dispatch(getSessionOptionsRequest(props.product.id, props.session.id));
    }
  }, [dispatch, props.product, props.session]);

  const onOpenCollapse = useCallback(
    (event: string | (string | SessionModel)[]) => {
      let isAlreadyLoaded = props.loadedSessionOptions.some(
        (data) =>
          data.sessionId === props.session.id &&
          data.productId === props.product.id
      );
      if (event.length > 0 && !isAlreadyLoaded) {
        openSessionOptionCollapse();
      }
    },
    [props, openSessionOptionCollapse]
  );

  useEffect(() => {
    if (props.selectedSessions.length === 1) {
      onOpenCollapse(props.selectedSessions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Collapse
      defaultActiveKey={
        props.selectedSessions.length > 1 || props.isDeletable ? 0 : 1
      }
      onChange={onOpenCollapse}
      className={'session-option-container'}
      expandIconPosition={'end'}
      expandIcon={(panelProps) => (
        <EmisysButton
          className={'collapse-button collapse-button-show-options'}
          isOutlined
        >
          {panelProps.isActive
            ? Translator.trans('session.collapse.hide')
            : Translator.trans('session.collapse.show')}

          <div className={'show-icon'}>
            {panelProps.isActive ? <UpOutlined /> : <DownOutlined />}
          </div>
        </EmisysButton>
      )}
    >
      <Panel
        key={1}
        className={'session-option-panel'}
        header={collapseHeader(props.session, showAvailabilityIndicators)}
      >
        <TicketOptions
          isDeletable={props.isDeletable}
          option={props.option}
          product={props.product}
          selectedOptions={props.selectedOptions}
          selectedSessions={props.selectedSessions}
          session={props.session}
          handleChangeQtyOption={props.handleChangeQtyOption}
          handleChangeQtySession={props.handleChangeQtySession}
          loadedSessionOptions={props.loadedSessionOptions}
          sessionOptionsList={props.sessionOptionsList}
        />
      </Panel>
    </Collapse>
  );
};

export default SessionListOptionItem;
