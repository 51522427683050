import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBasketRequest } from '../../redux/actions/basket.actions';
import { Skeleton } from 'antd';
import Title from '../Title/Title';
import { Spin } from 'antd';
import Container from '../../components/Container/Container';
import BasketItem from './BasketItem';
import BasketAccomItem from '../BasketSide/Items/BasketAccomItem';
import { checkReduxResponse } from '../../services/httpService';
import Translator from '../../services/translator';
import history from '../../services/history';
import { openNotificationError } from '../Notification/Notification';
import { CloseOutlined } from '@ant-design/icons';
import ItemModel from '../../Models/ItemModel';
import { pagesConstant } from '../../Constants/Constants';
import './Basket.css';

interface BasketRecapProps {
  editable: boolean;
  onClose?: () => void;
}

const BasketRecap = (props: BasketRecapProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [basketItems, setBasketItems] = useState([]);
  const currentBasket = useSelector((state: any) => state.basket.currentBasket);

  const getBasket = useCallback(() => {
    dispatch(getBasketRequest());
  }, [dispatch]);

  const handleSetBasketItems = useCallback((currentBasket) => {
    setLoading(currentBasket.loading);
    if (!currentBasket.loading && !currentBasket.error) {
      if (currentBasket.basket?.items) {
        setBasketItems(currentBasket.basket.items);
      } else {
        history.navigate(pagesConstant.tickets.home);
      }
    }
  }, []);

  useEffect(() => {
    if (currentBasket === null) {
      setLoading(true);
      getBasket();
    } else {
      setLoadingBasket(currentBasket.loading);
      if (checkReduxResponse(currentBasket, 'basket')) {
        handleSetBasketItems(currentBasket);
        setLoading(false);
      }

      if (currentBasket?.error) {
        openNotificationError(
          Translator.trans('error.internal_error.title'),
          currentBasket.error
        );
      }
    }
  }, [currentBasket, getBasket, handleSetBasketItems]);

  const setLoadingBasket = (isLoading: boolean) => {
    setLoadingItems(isLoading);
  };

  return (
    <Container className={'basket-summary-container'}>
      <Title value={Translator.trans('order.title')}>
        {!props.editable && <CloseOutlined onClick={props.onClose} />}
      </Title>
      <Skeleton active loading={loading}>
        <div>
          <Spin spinning={loadingItems}>
            {basketItems.length !== 0 ? (
              <>
                <div className="basket-items-container">
                  {basketItems.map((item: ItemModel) => {
                    if (item.reservation) {
                      return (
                        <BasketAccomItem
                          key={item.id}
                          basketItem={item}
                          isLoading={loadingItems}
                          setLoadingBasket={(isLoading) =>
                            setLoadingBasket(isLoading)
                          }
                          editable={props.editable}
                        />
                      );
                    } else {
                      return (
                        <BasketItem
                          key={item.id}
                          basketItem={item}
                          isLoading={loadingItems}
                          setLoadingBasket={(isLoading) =>
                            setLoadingBasket(isLoading)
                          }
                          editable={props.editable}
                        />
                      );
                    }
                  })}
                </div>
              </>
            ) : (
              <div className="empty-basket">
                {Translator.trans('order.empty_order')}
              </div>
            )}
          </Spin>
        </div>
      </Skeleton>
    </Container>
  );
};

export default BasketRecap;
