import { pagesConstant, shopConstants } from '../Constants/Constants';
import { addPreviousPathStorage, getShopTokenStorage } from './handleStorage';
import { getTokenStorage, removeTokenToStorage } from './handleTokens';
import history from './history';
import { shopConfig } from '../Globals/ShopConfig';
import { isTokenValid } from './parseJwt';

type MethodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type RequestInit = globalThis.RequestInit;

interface optionsInterface {
  no401Redirect?: boolean;
  formData?: boolean;
  signal?: AbortSignal;
}

const getHeaders = (
  method: MethodType,
  body?: unknown,
  options?: optionsInterface
): RequestInit => {
  const token = getTokenStorage();
  const header: RequestInit = {
    method: method,
    credentials: 'same-origin',
    headers: {},
  };

  if (options && options?.formData) {
    header.body = body ? convertBodyToFormData(body) : null;
  } else {
    header.headers['Content-Type'] = 'application/json';
    header.body = body ? JSON.stringify(body) : null;
  }

  if (isTokenValid(token)) {
    header.headers['Authorization'] = 'Bearer ' + token;
  } else {
    delete header.headers['Authorization'];
  }

  const privateShopToken = getShopTokenStorage();
  if (privateShopToken) {
    header.headers['X-emisys-privateShopToken'] = privateShopToken;
  }

  if (options && options?.signal) {
    header['signal'] = options.signal;
  }

  if (document.referrer) {
    header.headers['X-emisys-referrer'] = document.referrer;
  }

  return header;
};

export const get = (url: string | URL, options?: optionsInterface) => {
  return fetch(url, getHeaders('GET', null, options))
    .then((response) => {
      return checkStatus(response, options?.no401Redirect);
    })
    .catch(handleError);
};

export const post = (
  url: string | URL,
  body?: unknown,
  options?: optionsInterface
) => {
  return fetch(url, getHeaders('POST', body, options))
    .then((response) => {
      return checkStatus(response, options?.no401Redirect);
    })
    .catch(handleError);
};

export const update = (url: string | URL, body?: unknown) => {
  return fetch(url, getHeaders('PUT', body))
    .then(checkStatus)
    .catch(handleError);
};

export const patch = (url: string | URL, body?: unknown) => {
  return fetch(url, getHeaders('PATCH', body))
    .then(checkStatus)
    .catch(handleError);
};

export const del = (url: string | URL) => {
  return fetch(url, getHeaders('DELETE')).then(checkStatus).catch(handleError);
};

export const getUrl = (path: string, args = {}) => {
  let newPath = new URL(path, process.env.REACT_APP_URL_BACKEND);

  if (shopConfig?.pos?.preview) {
    const previewKey = sessionStorage.getItem('previewKey');
    if (previewKey) {
      newPath.searchParams.append('previewKey', previewKey);
    }
  }

  for (let key in args) {
    if (args.hasOwnProperty(key)) {
      if (
        typeof args[key] === 'object' &&
        args[key] &&
        !Array.isArray(args[key])
      ) {
        newPath.searchParams.append(
          key + '|' + args[key].operation,
          args[key].value
        );
      } else if (Array.isArray(args[key])) {
        args[key].forEach((data) => {
          newPath.searchParams.append(key + '|' + data.operation, data.value);
        });
      } else if (args[key] || args[key] === 0) {
        newPath.searchParams.append(key, args[key]);
      }
    }
  }

  return newPath;
};

const checkStatus = (response: any, no401Redirect?: boolean) => {
  if (response.status === 401) {
    removeTokenToStorage();
    if (!no401Redirect) {
      addPreviousPathStorage();
      history.navigate(pagesConstant.auth + shopConstants.SIGNIN);
    }
  }

  if (response.status >= 200 && response.status < 300) {
    if (response.status === 204 || response.status === 205) {
      return null;
    }
    return response.json();
  }

  return response.json().then((json) =>
    Promise.reject({
      status: response.status,
      ok: false,
      statusText: response.statusText,
      body: json,
    })
  );
};

const handleError = (error: any) => {
  error.response = {
    status: 0,
    statusText:
      'Cannot connect. Please make sure you are connected to internet.',
  };
  throw error;
};

const convertBodyToFormData = (body: any) => {
  let form_data = new FormData();

  for (let key in body) {
    form_data.append(key, body[key]);
  }

  return form_data;
};

interface ReduxPropsType {
  [key: string]: any;
  loading: boolean;
  error: boolean;
}
export const checkReduxResponse = (props: ReduxPropsType, attr: string) => {
  return props && props[attr] && !props.loading && !props.error;
};
