import OptionRenderingValuesModel from './OptionRenderingValuesModel';

export default class OptionRenderingModel {
  id: number;
  name: string;
  html_rendering: 'auto' | 'radio' | 'select' | 'button';
  values: OptionRenderingValuesModel[];

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.html_rendering = data.html_rendering;
    this.values = data.values.map(
      (value) => new OptionRenderingValuesModel(value)
    );
  }
}
