import { sessionFilters } from '../Constants/Constants';
import { formatTime, formatToFilterDate } from './formatDate';
import SessionModel from '../Models/SessionModel';
import { filterType } from '../components/Sessions/SessionFilters';

export function formatOccupantPayload(
  list: {
    occupantType: number;
    quantity: number;
  }[]
): string[] {
  let occupantTypePayload = [];
  list.forEach((element) => {
    if (element.quantity > 0) {
      occupantTypePayload.push(element.occupantType + ':' + element.quantity);
    }
  });

  // return [occupantId:quantity] - Ex. [1:2, 2:3]
  return occupantTypePayload;
}

export function createFilterSession(sessions: SessionModel[]) {
  let languages = new Set();
  let performers = new Set();
  let places = new Set();

  if (sessions && sessions.length > 0) {
    sessions.forEach((session: SessionModel) => {
      if (session.language) {
        languages.add(session.language);
      }

      if (session.performers && session.performers.length > 0) {
        session.performers.forEach((performer: string) => {
          performers.add(performer);
        });
      }

      if (session.places && session.places.length > 0) {
        session.places.forEach((place: string) => {
          places.add(place);
        });
      }
    });
  }

  return {
    languages: Array.from(languages),
    performers: Array.from(performers),
    places: Array.from(places),
  };
}

export function createDateFilterSession(sessions: SessionModel[]) {
  let dates = {};

  if (sessions && sessions.length > 0) {
    sessions.forEach((session) => {
      if (session.dateStart) {
        let startDate = formatToFilterDate(session.dateStart);
        let startTime = formatTime(session.dateStart);
        let endTime = formatTime(session.dateStop);
        let uniqueId = startTime + ' - ' + endTime;

        if (!Object.prototype.hasOwnProperty.call(dates, startDate)) {
          dates[startDate] = {};
        }
        if (!Object.prototype.hasOwnProperty.call(dates[startDate], uniqueId)) {
          dates[startDate][uniqueId] = [];
        }
        dates[startDate][uniqueId].push(session);
      }
    });
  }

  return dates;
}

export function filterSession(
  sessions: SessionModel[],
  filters: { [key in filterType]: filterType[] }
) {
  let filteredSessions = sessions;
  Object.keys(filters).forEach((key: filterType) => {
    if (key === sessionFilters.languages.key) {
      if (filters[key].length > 0) {
        filteredSessions = filteredSessions.filter((session: SessionModel) =>
          filters[key].some((filter): boolean => session.language === filter)
        );
      }
    }
    if (key === sessionFilters.performers.key) {
      if (filters[key].length > 0) {
        filteredSessions = filteredSessions.filter((session: SessionModel) =>
          filters[key].some((filter): boolean =>
            session.performers.includes(filter)
          )
        );
      }
    }
    if (key === sessionFilters.places.key) {
      if (filters[key].length > 0) {
        filteredSessions = filteredSessions.filter((session: SessionModel) =>
          filters[key].some((filter): boolean =>
            session.places.includes(filter)
          )
        );
      }
    }
  });

  return createDateFilterSession(filteredSessions);
}

export function createDateSession(sessions: SessionModel[]): {
  [date: string]: SessionModel;
} {
  let dates = {};

  if (sessions && sessions.length > 0) {
    sessions.forEach((session) => {
      if (session.dateStart) {
        let startDate = formatToFilterDate(session.dateStart);

        if (!Object.prototype.hasOwnProperty.call(dates, startDate)) {
          dates[startDate] = [session];
        } else {
          dates[startDate].push(session);
        }
      }
    });
  }

  return dates;
}
