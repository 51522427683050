import React, { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  Select,
  Radio,
  Input,
  InputNumber,
  Checkbox,
  Upload,
  Form,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import { genderList } from '../../../services/Gender';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import EmisysButton from '../../EmisysButton/EmisysButton';
import { getSelfLocaleName } from '../../../services/getLocaleName';
import allLocales from 'locale-codes';
import { shopConfig } from '../../../Globals/ShopConfig';
import Translator from '../../../services/translator';
import { DATE } from '../../../Constants/Constants';
import '../Form.css';

const { Option } = Select;
import countries from 'i18n-iso-countries';
type dayTypes = keyof typeof DATE;

const dummyRequest = (event: any) => {
  setTimeout(() => {
    event.onSuccess('ok');
  }, 0);
};

const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const handleChange = (props: any, event: any) => {
  props.onChange(event, props.uniqueId, props.saleKey);
};

const uploadImage = async (props: any, event: any) => {
  if (event?.status === 'done') {
    const currentFile = await getBase64(event.originFileObj);

    if (currentFile) {
      props.onChange({ image: currentFile }, props.uniqueId, props.saleKey);
    }
  }
};

const uploadFile = async (props: any, event: any) => {
  if (event?.status === 'done') {
    const currentFile = event.originFileObj;

    if (currentFile) {
      props.onChange(currentFile, props.uniqueId, props.saleKey);
    }
  }
};

const markdownDiv = (value: any) => (
  <ReactMarkdown className={'markdown-form-helper'}>{value}</ReactMarkdown>
);

export const CheckBoxType = (props: any) => (
  <div className={'form-item-container'}>
    <Form.Item
      name={props.uniqueId}
      initialValue={props.value}
      rules={[
        {
          validator: () =>
            !props.question.required || props.value
              ? Promise.resolve()
              : Promise.reject(
                  new Error(Translator.trans('form.validate.checkbox'))
                ),
        },
      ]}
    >
      <Checkbox
        checked={props.value}
        onChange={(event) => handleChange(props, event.target.checked)}
        className={'checkbox-type'}
      >
        <ReactMarkdown className={'checkbox-label'} >
          {props.question.label}
        </ReactMarkdown>
      </Checkbox>
    </Form.Item>
    {props.question.help && markdownDiv(props.question.help)}
  </div>
);

export const ChoiceType = (props: any) => (
  <div className={'form-item-container'}>
    <Form.Item
      name={props.uniqueId}
      label={props.question.label}
      rules={[{ required: props.question.required }]}
    >
      <Select
        showSearch
        mode={props.question.multiple ? 'multiple' : undefined}
        onChange={(event) => handleChange(props, event)}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {props.question.choices?.list &&
          Object.keys(props.question.choices.list).map((choice, index) => (
            <Option value={props.question.choices.list[choice]} key={index}>
              {choice}
            </Option>
          ))}
      </Select>
    </Form.Item>
    {props.question.help && markdownDiv(props.question.help)}
  </div>
);

export const FileType = (props: any) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (props.value !== '') {
      setFileList([
        {
          uid: '1',
          name: props.value.name,
          status: 'done',
          originFileObj: props.value,
        },
      ]);
    }
  }, [props.value]);

  const onChange = (event: any) => {
    setFileList(event.fileList);
    uploadFile(props, event.file).then();
  };

  return (
    <div className={'form-item-container'}>
      <Form.Item
        name={props.uniqueId}
        label={props.question.label}
        rules={[{ required: props.question.required }]}
      >
        <Upload
          listType="picture"
          maxCount={1}
          customRequest={dummyRequest}
          onChange={(event) => onChange(event)}
          fileList={fileList}
        >
          <EmisysButton
            icon={<UploadOutlined className={'upload-file-icon'} />}
            isOutlined
          >
            {props.question.label}
          </EmisysButton>
        </Upload>
      </Form.Item>
    </div>
  );
};

export const UserPictureType = (props: any) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (props.value.image) {
      setFileList([
        {
          uid: '1',
          name: 'picture',
          status: 'done',
          url: props.value.image,
        },
      ]);
    }
  }, [props.value]);

  const onChange = (event: any) => {
    setFileList(event.fileList);
    uploadImage(props, event.file).then();
  };

  return (
    <div className={'form-item-container'}>
      <Form.Item
        name={props.uniqueId}
        label={props.question.label}
        rules={[{ required: props.question.required }]}
      >
        <ImgCrop
          rotationSlider
          showReset
          modalClassName={'image-cropper-modal'}
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            fileList={fileList}
            accept={'image/*'}
            customRequest={(event) => dummyRequest(event)}
            onChange={(event) => onChange(event)}
            showUploadList={{
              showRemoveIcon: true,
              showPreviewIcon: false,
              showDownloadIcon: false,
            }}
          >
            {fileList.length === 1 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>
                  {Translator.trans('image.upload')}
                </div>
              </div>
            )}
          </Upload>
        </ImgCrop>
      </Form.Item>
    </div>
  );
};

export const IntegerType = (props: any) => (
  <div className={'form-item-container'}>
    <Form.Item
      name={props.uniqueId}
      label={props.question.label}
      rules={[{ required: props.question.required }]}
    >
      <InputNumber onChange={(event) => handleChange(props, event)} />
    </Form.Item>
    {props.question.help && markdownDiv(props.question.help)}
  </div>
);

export const TextAreaType = (props: any) => (
  <div className={'form-item-container'}>
    <Form.Item
      name={props.uniqueId}
      label={props.question.label}
      rules={[{ required: props.question.required }]}
    >
      <Input.TextArea
        rows={5}
        onChange={(event) => handleChange(props, event.target.value)}
      />
    </Form.Item>
    {props.question.help && markdownDiv(props.question.help)}
  </div>
);

export const TextType = (props: any) => (
  <div className={'form-item-container'}>
    <Form.Item
      name={props.uniqueId}
      label={props.question.label}
      rules={[{ required: props.question.required }]}
    >
      <Input onChange={(event) => handleChange(props, event.target.value)} />
    </Form.Item>
    {props.question.help && markdownDiv(props.question.help)}
  </div>
);

export const EmailType = (props: any) => (
  <div className={'form-item-container'}>
    <Form.Item
      name={props.uniqueId}
      label={props.question.label}
      rules={[{ type: 'email' }, { required: props.question.required }]}
    >
      <Input onChange={(event) => handleChange(props, event.target.value)} />
    </Form.Item>
    {props.question.help && markdownDiv(props.question.help)}
  </div>
);

export const UrlType = (props: any) => (
  <div className={'form-item-container'}>
    <Form.Item
      name={props.uniqueId}
      label={props.question.label}
      rules={[{ required: props.question.required }, { type: 'url' }]}
    >
      <Input onChange={(event) => handleChange(props, event.target.value)} />
    </Form.Item>
    {props.question.help && markdownDiv(props.question.help)}
  </div>
);

export const BirthdayType = (props: any) => {
  let date = props.value.split('-');
  let day = date[2] ? date[2] : '';
  let month = date[1] ? date[1] : '';
  let year = date[0] ? date[0] : '';

  const monthRef = useRef(null);
  const yearRef = useRef(null);

  const handleChangeBirth = (props: any, event: any, type: dayTypes) => {
    if (type === DATE.day) {
      day = event?.toString().length <= 2 ? event : '';

      if (day.toString().length >= 2) {
        monthRef.current.focus();
      }
    }

    if (type === DATE.month) {
      month = event?.toString().length <= 2 ? event : '';

      if (month.toString().length >= 2) {
        yearRef.current.focus();
      }
    }

    if (type === DATE.year) {
      year = event ? event : '';
    }

    if (year || month || day) {
      handleChange(props, year + '-' + month + '-' + day);
    } else {
      handleChange(props, '');
    }
  };

  const maxDay = () => {
    if (month && year && month >= 1 && month <= 12) {
      return new Date(year, month, 0).getDate();
    }

    return 31;
  };

  const isError = (type: dayTypes) => {
    if (type === DATE.day) {
      return day !== '' && (day < 1 || day > maxDay()) ? 'error' : '';
    }

    if (type === DATE.month) {
      return month !== '' && (month < 1 || month > 12) ? 'error' : '';
    }

    if (type === DATE.year) {
      const currentYear = new Date().getFullYear();
      return year !== '' && (year < currentYear - 120 || year > currentYear)
        ? 'error'
        : '';
    }
  };

  return (
    <div className={'form-item-container'}>
      <Form.Item
        name={props.uniqueId}
        label={props.question.label}
        className={'birthday-container'}
        required={props.question.required}
        rules={[{ required: props.question.required }]}
      >
        <div className={'birthday-input-containers'}>
          <InputNumber
            status={isError('day')}
            placeholder={Translator.trans('user.property.birth.day')}
            onChange={(event) => handleChangeBirth(props, event, 'day')}
            value={day ? day : ''}
            type={'number'}
          />
          <InputNumber
            ref={monthRef}
            status={isError('month')}
            placeholder={Translator.trans('user.property.birth.month')}
            onChange={(event) => handleChangeBirth(props, event, 'month')}
            value={month ? month : ''}
            type={'number'}
          />
          <InputNumber
            ref={yearRef}
            maxLength={4}
            status={isError('year')}
            placeholder={Translator.trans('user.property.birth.year')}
            onChange={(event) => handleChangeBirth(props, event, 'year')}
            value={year ? year : ''}
            type={'number'}
          />
        </div>
      </Form.Item>
      {props.question.help && markdownDiv(props.question.help)}
    </div>
  );
};

export const PhoneNumberType = (props: any) => (
  <div className={'form-item-container'}>
    <Form.Item
      name={props.uniqueId}
      label={props.question.label}
      rules={[{ required: props.question.required }]}
    >
      <PhoneInput
        country={shopConfig.locale.countryCode.toLowerCase()}
        value={props.value}
        onChange={(event) => handleChange(props, event)}
        inputStyle={{
          marginTop: '10px',
          padding: '7px 10px 7px 58px',
          width: '100%',
        }}
      />
    </Form.Item>
    {props.question.help && markdownDiv(props.question.help)}
  </div>
);

export const GenderType = (props: any) => (
  <div className={'form-item-container'}>
    <Form.Item
      name={props.uniqueId}
      label={props.question.label}
      rules={[{ required: props.question.required }]}
    >
      <Radio.Group
        value={props.value}
        onChange={(event) => handleChange(props, event.target.value)}
      >
        {genderList.map((gender: any, index: number) => (
          <Radio key={index} value={gender.value}>
            {gender.label}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
    {props.question.help && markdownDiv(props.question.help)}
  </div>
);

export const CountryType = (props: any) => {
  const language = shopConfig.locale.language;
  countries.registerLocale(
    require(`i18n-iso-countries/langs/${language}.json`)
  );
  const allCountry = countries.getNames(language, {
    select: 'official',
  });

  return (
    <div className={'form-item-container'}>
      <Form.Item
        name={props.uniqueId}
        label={props.question.label}
        rules={[{ required: props.question.required }]}
      >
        <Select
          showSearch
          mode={props.multiple ? 'multiple' : undefined}
          value={props.value}
          onChange={(event) => handleChange(props, event)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {allCountry &&
            Object.keys(allCountry).map((data, index) => (
              <Option key={index} value={data}>
                {allCountry[data]}
              </Option>
            ))}
        </Select>
      </Form.Item>
      {props.question.help && markdownDiv(props.question.help)}
    </div>
  );
};

export const LanguageType = (props: any) => {
  const allLanguages = allLocales.all ?? [];

  return (
    <div className={'form-item-container'}>
      <Form.Item
        name={props.uniqueId}
        label={props.question.label}
        rules={[{ required: props.question.required }]}
      >
        <Select
          showSearch
          mode={props.multiple ? 'multiple' : undefined}
          value={props.value}
          onChange={(event) => handleChange(props, event)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {allLanguages.map((data, index) => (
            <Option key={index} value={data.tag}>
              {getSelfLocaleName(data.tag)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {props.question.help && markdownDiv(props.question.help)}
    </div>
  );
};
