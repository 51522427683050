import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmisysButton from '../EmisysButton/EmisysButton';
import Quantity from '../Quantity/Quantity';
import Price from '../Price/Price';
import { postBasketItemRequest } from '../../redux/actions/basket.actions';
import { dispatchAddToCart } from '../../services/tracking';
import { shopConfig } from '../../Globals/ShopConfig';
import Translator from '../../services/translator';
import './UpsellingItem.css';
import UpsellingModel from '../../Models/UpsellingModel';

interface UpsellingItemInterface {
  upsellingItem: UpsellingModel;
}

const UpsellingItem = (props: UpsellingItemInterface) => {
  const dispatch = useDispatch();
  const qtyMinPerSale = props.upsellingItem.quantityMinPerSale ?? 1;
  const [loadingItem, setLoadingItem] = useState(false);
  const [quantity, setQuantity] = useState(qtyMinPerSale);

  const currentBasket = useSelector((state: any) => state.basket.currentBasket);

  const postBasketItem = useCallback(() => {
    dispatch(postBasketItemRequest(newBasketItem.current));
  }, [dispatch]);

  const newBasketItem = useRef({
    formuleId: props.upsellingItem.id,
    quantity: quantity,
    sessionId: null,
    project: shopConfig.project.name,
    pos: shopConfig.pos.slug,
  });

  useEffect(() => {
    if (currentBasket) {
      if (!currentBasket.loading) {
        setLoadingItem(false);
        setQuantity(qtyMinPerSale);
      }
    }
  }, [currentBasket, qtyMinPerSale]);

  useEffect(() => {
    newBasketItem.current.quantity = quantity;
  }, [quantity]);

  const handleAddUpselling = () => {
    postBasketItem();
    setLoadingItem(true);
    dispatchAddToCart(props.upsellingItem.name, props.upsellingItem.price);
  };

  return (
    <div className="upsellingItemContainer">
      <div className="upsellingItemContainerName">
        <div className="upsellingItemName">
          {props.upsellingItem.marketingName}
        </div>
      </div>
      <div className="upsellingItemContainerQtty">
        {!props.upsellingItem.isSoldOut && (
          <>
            <div className="upselling-item-info">
              <Price
                currentPrice={props.upsellingItem.price}
                currency={shopConfig.locale.currencyCode}
              />
            </div>
            <div className="upselling-item-info">
              <div>
                <Quantity
                  minQuantity={qtyMinPerSale}
                  remainingQuantity={props.upsellingItem.availableQuantity}
                  quantityMaxPerSale={props.upsellingItem.quantityMaxPerSale}
                  handleMin={() =>
                    setQuantity(
                      quantity - props.upsellingItem.quantityIncrement
                    )
                  }
                  currentValue={quantity}
                  handlePlus={() =>
                    setQuantity(
                      quantity + props.upsellingItem.quantityIncrement
                    )
                  }
                />
              </div>
            </div>
            <div className="upselling-item-info">
              <EmisysButton
                loading={loadingItem}
                disabled={props.upsellingItem.isSoldOut}
                onClick={handleAddUpselling}
                isOutlined
              >
                {props.upsellingItem.isSoldOut
                  ? Translator.trans('product.soldout')
                  : Translator.trans('product.button.add')}
              </EmisysButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UpsellingItem;
