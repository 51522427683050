import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
import { DeleteOutlined } from '@ant-design/icons';
import { shopConfig } from '../../Globals/ShopConfig';
import Translator from '../../services/translator';
import ProductModel from '../../Models/ProductModel';
import { getPlacementListRequest } from '../../redux/actions/placement.actions';
import { useDispatch, useSelector } from 'react-redux';
import SessionModel from '../../Models/SessionModel';
import { checkReduxResponse } from '../../services/httpService';
import { formatMoney } from '../../services/formatMoney';
import { Language } from '@seatsio/seatsio-types';
import { openNotificationError } from '../Notification/Notification';

interface TicketPlacementProps {
  product: ProductModel;
  session: SessionModel;
  placementEventKey: string;
  onChangeSeats: (seats: any[]) => void;
  isSelectionValid: (value: boolean) => void;
}

const TicketPlacement = (props: TicketPlacementProps) => {
  const dispatch = useDispatch();
  const selectedSeats = useRef([]);
  const [seats, setSeats] = useState(selectedSeats.current);
  const [currentEventKey, setCurrentEventKey] = useState('');
  const [pricing, setPricing] = useState(null);
  const locale = useMemo(() => shopConfig.locale.locale, []);
  const currency = useMemo(() => shopConfig.locale.currencyCode, []);

  const placementList = useSelector(
    (state: any) => state.placement.placementList
  );

  const getPlacementList = useCallback(() => {
    dispatch(
      getPlacementListRequest({
        product_id: props.product.id,
        session_id: props.session.id,
      })
    );
  }, [dispatch, props.product.id, props.session.id]);

  useEffect(() => {
    if (placementList) {
      if (checkReduxResponse(placementList, 'list')) {
        const pricingFormat = placementList.list.map((place: any) => ({
          category: place.category.key,
          ticketTypes: place.options.map((option: any) => ({
            ticketType: option.option.toString(),
            price: option.price,
            label: option.name,
          })),
        }));

        setPricing(pricingFormat);
      }
      if (placementList.error) {
        openNotificationError(
          '',
          placementList.errorMessage ||
            Translator.trans('error.internal_error.title')
        );
      }
    }
  }, [placementList]);

  useEffect(() => {
    getPlacementList();
  }, [getPlacementList]);

  useEffect(() => {
    if (props.placementEventKey !== currentEventKey) {
      setCurrentEventKey(props.placementEventKey);
      selectedSeats.current = [];
      setSeats(selectedSeats.current);
      props.onChangeSeats(selectedSeats.current);
    }
  }, [props.placementEventKey, currentEventKey, props]);

  const onSelectSeats = (seat: any, selectedTicketType: any) => {
    seat.price = selectedTicketType?.price
      ? selectedTicketType?.price
      : props.product.price.amount;
    selectedSeats.current = [...selectedSeats.current, seat];
    setSeats(selectedSeats.current);
    props.onChangeSeats(selectedSeats.current);
  };

  const onDeselectSeats = (seat: any) => {
    selectedSeats.current = selectedSeats.current.filter(
      (item: any) => item.id !== seat.id
    );
    setSeats(selectedSeats.current);
    props.onChangeSeats(selectedSeats.current);
  };

  return (
    <>
      <h3>{Translator.trans('ticket.placement.title')}</h3>
      {shopConfig.placementMap?.seatsioApiWorkspaceKey && (
        <SeatsioSeatingChart
          divId={'seats-ticket-placement'}
          workspaceKey={shopConfig.placementMap.seatsioApiWorkspaceKey}
          event={currentEventKey}
          region={shopConfig.placementMap.seatsioApiRegion}
          language={shopConfig.locale.language as Language}
          session="start"
          onObjectSelected={onSelectSeats}
          onObjectDeselected={onDeselectSeats}
          maxSelectedObjects={props.product.quantityMaxPerSale}
          pricing={pricing}
          priceFormatter={(price) => formatMoney(currency, locale, price)}
          selectionValidators={[{ type: 'noOrphanSeats', mode: 'lenient' }]}
          objectColor={(object: any) =>
            object.status === 'free'
              ? object.forSale
                ? '#90CA78'
                : '#a4a4a4'
              : '#5b5b5b'
          }
          onSelectionValid={() => props.isSelectionValid(true)}
          onSelectionInvalid={() => props.isSelectionValid(false)}
        />
      )}
      {seats.length !== 0 && (
        <div className={'selected-seats-container'}>
          {seats.map((seat: any, index: number) => {
            return (
              <Fragment key={seat.uuid}>
                <div className={'selected-seat'}>
                  <div className="seat-informations">
                    <div>
                      {seat.category?.label
                        ? Translator.transHtml('ticket.placementSelection', {
                            label: seat.category.label,
                            seat: seat.labels?.displayedLabel,
                            price: formatMoney(currency, locale, seat.price),
                          })
                        : Translator.transHtml(
                            'ticket.placementSelection.simple',
                            {
                              seat: seat.labels?.displayedLabel,
                              price: formatMoney(currency, locale, seat.price),
                            }
                          )}
                    </div>

                    <DeleteOutlined
                      onClick={() => {
                        onDeselectSeats(seat);
                        seat.deselect();
                      }}
                      className={'delete-seat'}
                    />
                  </div>
                </div>
                {index % 2 ? <div className="break-flex" /> : ''}
              </Fragment>
            );
          })}
        </div>
      )}
    </>
  );
};

export default TicketPlacement;
